<template>
  <div class="card p-shadow-6">
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog></ConfirmDialog>

    <h1>Faltas</h1>
    <Divider />
    <div class="p-field p-grid">
      <div class="p-col-12 p-md-3 p-mt-1">
        <label for="year-selector">Ano</label>
        <InputNumber
          id="year-selector"
          class="p-ml-2 p-mr-2"
          :value="selectedYear"
          showButtons
          buttonLayout="horizontal"
          incrementButtonIcon="pi pi-plus"
          decrementButtonIcon="pi pi-minus"
          inputStyle="width: 75px"
          :step="1"
          :min="currentYear - 10"
          :max="currentYear"
          :useGrouping="false"
          @input="yearChanged"
        />
      </div>
      <div class="p-col-12 p-md-3 p-mt-1">
        <Button
          label="Adicionar Falta"
          icon="pi pi-plus"
          class="p-button-success p-button-outlined"
          @click="addMiss"
        />
      </div>
    </div>
    <Divider />
    <DataTable
      :value="missList"
      :filters.sync="filters"
      filterDisplay="row"
      :loading="loading"
      :paginator="true"
      :rows="20"
      class="p-datatable-sm"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50]"
      currentPageReportTemplate="Mostrar {first} ao {last} de {totalRecords} faltas"
      responsiveLayout="scroll"
    >
      <template #empty> Não existem faltas. </template>
      <template #loading> A carregar. Por favor Aguarde... </template>
      <Column
        field="date_start_day"
        header="Data Início"
        sortable
        filterField="date_start_day"
        dataType="date"
      >
        <template #body="slotProps">
          <span class="p-column-title">Início:</span>
          {{ slotProps.data.start_day }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <v-date-picker
            v-model="filterModel.value"
            @input="filterCallback()"
            :masks="{
              input: 'YYYY-MM-DD',
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="p-column-filter p-inputtext p-component"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </v-date-picker>
        </template>
      </Column>
      <Column
        field="date_end_day"
        header="Data Fim"
        sortable
        filterField="date_end_day"
        dataType="date"
      >
        <template #body="slotProps">
          <span class="p-column-title">Fim:</span>
          {{ slotProps.data.end_day }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <v-date-picker
            v-model="filterModel.value"
            @input="filterCallback()"
            :masks="{
              input: 'YYYY-MM-DD',
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="p-column-filter p-inputtext p-component"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </v-date-picker>
        </template>
      </Column>
      <Column field="type" header="Tipo" sortable filterField="type">
        <template #body="slotProps">
          <span class="p-column-title">Tipo:</span>
          {{ slotProps.data.type }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column field="reason" header="Observações" filterField="reason">
        <template #body="slotProps">
          <span class="p-column-title">Observações:</span>
          {{ slotProps.data.reason }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="approved"
        header="Aprovada"
        filterField="approved"
        dataType="boolean"
        sortable
      >
        <template #body="slotProps">
          <span class="p-column-title">Aprovada:</span>
          {{ slotProps.data.approved == true ? "Sim" : "Não" }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            @input="filterCallback()"
            :options="[
              { value: false, label: 'Não' },
              { value: true, label: 'Sim' },
            ]"
            optionValue="value"
            optionLabel="label"
            class="p-column-filter"
            :showClear="true"
          >
          </Dropdown>
        </template>
      </Column>

      <Column>
        <template #body="slotProps">
          <Button
            v-if="!slotProps.data.approved"
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-button-outlined p-mr-2"
            @click="editMiss(slotProps.data)"
          />
          <Button
            v-if="slotProps.data.approved"
            icon="pi pi-eye"
            class="p-button-rounded p-button-success p-button-outlined p-mr-2"
            @click="viewMiss(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>

    <Dialog
      :visible.sync="missForm"
      :style="{ width: $isMobile() ? '100vw' : '50vw' }"
      :header="'Adicionar Falta'"
      :modal="true"
      :contentStyle="{ overflow: 'hide' }"
    >
      <form
        name="missForm"
        class="p-col-12"
        @submit.prevent="saveMiss"
        style="min-height: 50vh"
      >
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-6 p-mt-2">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-calendar"></i>
              </span>
              <span class="p-float-label">
                <v-date-picker
                  :disabled="missItem.action == 'view'"
                  id="start_day"
                  name="start_day"
                  v-model="missItem.start_day"
                  v-validate="missItem.action == 'view' ? '' : 'required'"
                  :masks="{
                    input: 'DD-MM-YYYY',
                  }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <span class="p-float-label">
                      <input
                        v-bind:class="[
                          { 'p-error': errors.has('start_day') },
                          'form-control',
                          'p-inputtext',
                          'p-component',
                          'p-filled',
                        ]"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                      <label for="inputtext">Início</label>
                    </span>
                  </template>
                </v-date-picker>
              </span>
            </div>
            <small v-if="errors.has('start_day')" class="p-error" role="alert">
              Dia de início é obrigatório
            </small>
          </div>

          <div class="p-field p-col-12 p-md-6 p-mt-2">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-calendar"></i>
              </span>
              <span class="p-float-label">
                <v-date-picker
                  :disabled="missItem.action == 'view'"
                  id="end_day"
                  name="end_day"
                  v-model="missItem.end_day"
                  :min-date="
                    missItem.start_day ? new Date(missItem.start_day) : ''
                  "
                  v-validate="missItem.action == 'view' ? '' : 'required'"
                  :masks="{
                    input: 'DD-MM-YYYY',
                  }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <span class="p-float-label">
                      <input
                        v-bind:class="[
                          { 'p-error': errors.has('end_day') },
                          'form-control',
                          'p-inputtext',
                          'p-component',
                          'p-filled',
                        ]"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                      <label for="inputtext">Fim</label>
                    </span>
                  </template>
                </v-date-picker>
              </span>
            </div>
            <small v-if="errors.has('end_day')" class="p-error" role="alert">
              Dia de fim é obrigatório
            </small>
          </div>

          <div class="p-field p-col-12 p-md-6 p-mt-3">
            <span class="p-float-label">
              <Dropdown
                :disabled="missItem.action == 'view'"
                id="business"
                v-model="missItem.type_reference"
                name="type_reference"
                v-validate="missItem.action == 'view' ? '' : 'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('type_reference') },
                  'form-control',
                ]"
                :inputId="'type_reference'"
                :showClear="true"
                :options="missTypeList"
                :optionLabel="'name'"
                :optionValue="'reference'"
                :dataKey="'reference'"
              />
              <label for="business">Tipo</label>
            </span>
            <small
              v-if="errors.has('type_reference')"
              class="p-error"
              role="alert"
            >
              Tipo é obrigatório
            </small>
          </div>

          <div class="p-field p-col-12 p-md-6 p-mt-3">
            <span class="p-float-label">
              <InputText
                :disabled="missItem.action == 'view'"
                type="text"
                v-model="missItem.reason"
                v-validate="missItem.action == 'view' ? '' : 'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('reason') },
                  'form-control',
                ]"
                :id="'reason'"
                :name="'reason'"
              />
              <label for="pats">Motivo da Falta</label>
            </span>
            <small v-if="errors.has('reason')" class="p-error" role="alert">
              Motivo da falta é obrigatório
            </small>
          </div>
          <div class="p-field p-grid p-col-12">
            <div
              class="p-col-12 p-md-2"
              v-if="missItem.action != 'new' && missItem.viewFileButton"
            >
              <Button
                label="Ver Ficheiros"
                icon="pi pi-eye"
                class="p-button-success p-button-outlined p-mb-2"
                style="width: 100%"
                @click="getMissFiles"
              />
            </div>
            <div v-if="missItem.action != 'view'" class="p-col-12 p-md-3">
              <FileUpload
                ref="uploadInput"
                mode="basic"
                name="files[]"
                chooseLabel="Adicionar Ficheiro"
              />
            </div>
          </div>
          <Loading
            :active.sync="missItem.looderFiles"
            :is-full-page="true"
          ></Loading>
          <div
            class="p-field p-col-12 p-md-4"
            v-for="item in missItem.files"
            :key="item.referece"
          >
            <div v-if="item.type == 'pdf'" class="pdf-viewer">
              <embed
                :src="item.src"
                type="application/pdf"
                width="100%"
                height="300px"
              />
            </div>
            <div v-else style="text-align: center">
              <ImagePreview :src="item.src" alt="Image" width="150" preview />
            </div>
            <div class="p-col-12 p-text-center p-mt-1">
              <Button
                v-if="item.type == 'pdf'"
                icon="pi pi-search"
                class="p-button-rounded p-button-outlined p-button-raised"
                aria:haspopup="true"
                aria-controls="overlay_panel"
                @click="item.fullView = true"
              />
              <Sidebar :visible.sync="item.fullView" position="full">
                <div v-if="item.type == 'pdf'" class="pdf-fullview">
                  <embed
                    :src="item.src"
                    type="application/pdf"
                    width="100%"
                    height="500px"
                  />
                </div>
              </Sidebar>
              <Button
                v-if="missItem.action != 'view'"
                icon="pi pi-times"
                class="p-button-rounded p-button-danger p-button-outlined p-button-raised p-ml-1"
                @click="removeFile(item.reference)"
              />
            </div>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          v-if="missItem.action != 'view'"
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-text p-button-success"
          @click="saveMiss"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-text p-button-danger"
          @click="closeForm"
        />
      </template>
    </Dialog>

    <Dialog
      :visible.sync="deleteMissDialog"
      :style="{ width: '20vw' }"
      header="Anular Falta"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="missItem">
          <span> Tem a certeza que pretende esta falta? </span>
        </span>
      </div>
      <template #footer>
        <Button
          label="Sim"
          icon="pi pi-check"
          class="p-button-text p-button-success"
          @click="deleteMiss"
        />
        <Button
          label="Não"
          icon="pi pi-times"
          class="p-button-text p-button-danger"
          @click="deleteMissDialog = false"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import missService from "../services/miss.service";
import filesService from "../services/files.service";
import MissModel from "../models/miss";
import { getOnlyDate } from "../helpers/helpers";
import loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { FilterMatchMode } from "primevue/api/";

export default {
  name: "Miss",
  components: {
    Loading: loading,
  },
  data() {
    return {
      loading: true,
      missList: [],
      missTypeList: [],
      filters: {
        date_start_day: { value: null, matchMode: FilterMatchMode.DATE_IS },
        date_end_day: { value: null, matchMode: FilterMatchMode.DATE_IS },
        type: { value: null, matchMode: FilterMatchMode.CONTAINS },
        reason: { value: null, matchMode: FilterMatchMode.CONTAINS },
        approved: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      selectedYear: new Date().getFullYear(),
      currentYear: new Date().getFullYear(),
      missItem: {},
      missForm: false,
      deleteMissDialog: false,
    };
  },
  created() {
    this.loading = true;
    this.getMissType().then(() => this.getMisses());
  },
  methods: {
    yearChanged(year) {
      if (this.selectedYear != year) {
        this.selectedYear = year;
        this.getMisses();
      }
    },
    getMissType() {
      return missService.getMissTypes().then((response) => {
        return (this.missTypeList = response);
      });
    },
    getMisses() {
      this.loading = true;
      let userId = this.$store.state.auth.user.id;
      return missService
        .getUserMissByYear(userId, this.selectedYear)
        .then((response) => {
          this.loading = false;
          response = response.map((v) => ({
            ...v,
            date_start_day: new Date(v.start_day),
            date_end_day: new Date(v.end_day),
          }));
          return (this.missList = response);
        });
    },
    closeForm() {
      this.$validator.pause();
      this.$validator.reset();
      this.missForm = false;
      this.missItem = {};
    },
    addMiss() {
      this.missItem = new MissModel("new");
      this.missForm = true;
    },
    saveMiss() {
      this.$validator.validateAll().then((isValid) => {
        if (
          !isValid ||
          (this.missItem.end_day != undefined &&
            this.missItem.start_day &&
            this.missItem.end_day < this.missItem.start_day)
        ) {
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        if (this.missItem.action === "new") {
          return this.createMiss();
        } else if (this.missItem.action === "update") {
          return this.updateMiss();
        }
      });
    },
    createMiss() {
      let data = {
        user_id: this.$store.state.auth.user.id,
        type_id: this.missItem.type_reference,
        start: getOnlyDate(this.missItem.start_day),
        end: getOnlyDate(this.missItem.end_day),
        reason: this.missItem.reason,
      };

      return missService
        .createMiss(data)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao criar a falta",
              life: 3000,
            });
          }
          this.missList.unshift(response);
          this.$toast.add({
            severity: "success",
            summary: "Falta marcada",
            detail: "A falta foi criada com sucesso",
            life: 3000,
          });

          return response;
        })
        .then((miss) => {
          this.$refs.uploadInput.files.forEach((file) => {
            this.myUploader(file, miss);
          });
        })
        .then(() => this.closeForm());
    },
    editMiss(info) {
      return this.loadMiss("update", info);
    },
    viewMiss(info) {
      return this.loadMiss("view", info);
    },
    loadMiss(action, info) {
      this.missItem = new MissModel(action);
      this.missItem.action = action;
      this.missItem.reference = info.reference;
      this.missItem.user_name = info.user_name;
      this.missItem.start_day = info.start_day;
      this.missItem.end_day = info.end_day;
      this.missItem.type_reference = info.type_reference;
      this.missItem.reason = info.reason;
      this.missItem.approved = info.approved;
      this.missItem.files = [];
      this.missForm = true;
    },
    updateMiss() {
      let data = {
        reference: this.missItem.reference,
        type_id: this.missItem.type_reference,
        start:
          this.missItem.start_day instanceof Date
            ? getOnlyDate(this.missItem.start_day)
            : this.missItem.start_day,
        end:
          this.missItem.end_day instanceof Date
            ? getOnlyDate(this.missItem.end_day)
            : this.missItem.end_day,
        reason: this.missItem.reason,
      };

      let missRef = this.missItem.reference;
      const areYou = (missAux) => {
        return missAux.reference == missRef;
      };

      return missService
        .updateMiss(data)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao actualizar a falta",
              life: 3000,
            });
          }
          let missIndex = this.missList.findIndex(areYou);
          this.missList[missIndex].start_day = response.start_day;
          this.missList[missIndex].end_day = response.end_day;
          this.missList[missIndex].type = response.type;
          this.missList[missIndex].type_reference = response.type_reference;
          this.missList[missIndex].reason = response.reason;
          this.$toast.add({
            severity: "success",
            summary: "Falta actualizada",
            detail: "A falta foi actualizada com sucesso",
            life: 3000,
          });
          return this.missList[missIndex];
        })
        .then((miss) => {
          this.$refs.uploadInput.files.forEach((file) => {
            this.myUploader(file, miss);
          });
        })
        .then(() => this.closeForm());
    },
    confirmDeleteMiss(info) {
      this.missItem = info;
      this.deleteMissDialog = true;
    },
    deleteMiss() {
      let missRef = this.missItem.reference;
      const areYou = (missAux) => {
        return missAux.reference == missRef;
      };
      let data = {
        reference: this.missItem.reference,
      };
      this.deleteMissDialog = false;
      this.missItem = {};
      return missService.removeMiss(data).then((response) => {
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Ocorreu um erro ao anular a falta",
            life: 3000,
          });
        }
        let missIndex = this.missList.findIndex(areYou);
        if (missIndex >= 0) {
          this.missList.splice(missIndex, 1);
        }
        return this.$toast.add({
          severity: "success",
          summary: "Falta anulada",
          detail: "A falta foi anulada com sucesso",
          life: 3000,
        });
      });
    },
    myUploader(file, miss) {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("origin", "ABS");
      formData.append("origin_reference", miss.reference);
      formData.append("resume", miss.reason);

      return filesService.addFile(formData).then((response) => {
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Ocorreu um erro ao inserir o ficheiro",
            life: 3000,
          });
        }
        miss.files.push({
          reference: response.reference,
          src: file.objectURL,
        });

        return this.$toast.add({
          severity: "success",
          summary: "Ficheiro inserido",
          detail: "O Ficheiro inserido com sucesso",
          life: 3000,
        });
      });
    },
    removeFile(fileReference) {
      const fileAreYou = (fileAux) => {
        return fileAux.reference == fileReference;
      };
      const missAreYou = (missAux) => {
        return missAux.reference == this.missItem.reference;
      };
      this.$confirm.require({
        header: "Faltas - Apagar Ficheiro",
        message: "Tem a certeza que pretende apagar este ficheiro?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-text p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-text p-button-danger p-button p-component",
        accept: () => {
          let data = {
            reference: fileReference,
          };
          filesService.removeFile(data).then((response) => {
            if (!response) {
              return this.$toast.add({
                severity: "error",
                summary: "Erro",
                detail: "Ocorreu um erro ao eliminar o ficheiro",
                life: 3000,
              });
            }

            let missIndex = this.missList.findIndex(missAreYou);

            let fileIndex2 = this.missList[missIndex].files.findIndex(
              fileAreYou
            );

            let fileIndex = this.missItem.files.findIndex(fileAreYou);
            this.missItem.files.splice(fileIndex, 1);
            this.missList[missIndex].files.splice(fileIndex2, 1);

            return this.$toast.add({
              severity: "success",
              summary: "Ficheiro eliminado",
              detail: "O ficheiro foi eliminado com sucesso",
              life: 3000,
            });
          });
        },
        reject: () => {},
      });
    },
    getMissFiles() {
      this.missItem.files = [];
      this.missItem.looderFiles = true;
      let data = {
        origin_reference: this.missItem.reference,
      };
      filesService.getFileFromOriginReference(data).then((response) => {
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Ocorreu um erro ao eliminar o ficheiro",
            life: 3000,
          });
        }
        response.forEach((file) => {
          file["fullView"] = false;
          if (file.type !== undefined && file.type == "pdf") {
            file.src = `data:application/${file.type};base64,${file.src}`;
          } else if (file.type !== undefined) {
            file.src = `data:image/${file.type};base64,${file.src}`;
          }

          this.missItem.files.push(file);
        });
        this.missItem.viewFileButton = false;
        this.missItem.looderFiles = false;
      });
    },
  },
};
</script>
<style scoped lang="scss">
#year-selector {
  width: 60px;
}
.pdf-viewer {
  text-align: center;
}
</style>
